import IMask from 'imask';
import _ from 'lodash';

$(function(){
  const moneyMaskOptions = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    padFractionalZeros: true,
    radis: ',',
  }

  const momentFormat = 'DD/MM/YYYY';

  const dateMaskOptions = {
    mask: Date,
    pattern: momentFormat,
    lazy: false,
    min: new Date(1000, 0, 1),
    max: new Date(9999, 0, 1),

    format: date => moment(date).format(momentFormat),
    parse: str => moment(str, momentFormat),

    blocks: {
      YYYY: {
        mask: IMask.MaskedRange,
        from: 1970,
        to: 2030
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12
      },
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31
      },
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59
      }
    }
  }


  let moneyInputs = document.getElementsByClassName('input-money-mask');

  _.each(moneyInputs, input => {
    IMask(input, moneyMaskOptions)
  })

  let dateInputs = document.getElementsByClassName('input-date-mask');
  _.each(dateInputs, input => {
    IMask(input, dateMaskOptions)
  })
});
