import React, { useState, useEffect } from 'react';
import { Table, Spinner } from 'reactstrap';

const ComplianceBulkLoadActivitiesFilePreview = (props) => {
  const { fileData} = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!fileData.filePresent) return;
    setIsLoading(true);

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [fileData]);

  const renderTableRows = (data) => {
    return _.map(data, (row, index) => (
      <tr key={index}>
        {_.map(Object.values(row), (cell, idx) => (
          <td key={idx}>{cell}</td>
        ))} </tr>
    ));
  };

  const renderTable = () => {
    if (fileData.totalRows !== 0) {
      return (
        <div className=''>
          <h5 className='text-semibold'>Previsualizacion</h5>
          <Table size="sm" className="table table-bordered">
            <thead className='bg-primary'>
              <tr>
                {_.map(fileData.headers, (header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>{renderTableRows(fileData.sampleRows)}</tbody>
          </Table>
          <span>3 de un total de {fileData.totalRows}</span>
        </div>
      )
    }
  }

  return (
    <div className='p-3'>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner color="primary" />
        </div>
      ) : (
        renderTable()
      )}
    </div>
  );
};

export default ComplianceBulkLoadActivitiesFilePreview;

