import React from "react";

import ControldocFormTemplateForm from "../../../controldoc_form/template/form";
import { create, update } from "./axios";

const AbstractControldocFormTemplateForm = props => {
  const currentEntityType = props?.currentEntityType || {};

  return(
    <div className="row">
      <div className="col-12">
        <ControldocFormTemplateForm
          template={ props?.template }
          currentUser={ props?.currentUser }
          currentEntityType={ currentEntityType }
          formName="controldoc_form_template"
          imagePicker={ props?.imagePicker || {} }
          data={ props?.data || {} }
          actions={{
            createAction: create,
            updateAction: update,
            showPath: Routes.abstract_controldoc_form_template_path,
            indexPath: Routes.abstract_controldoc_form_templates_path({ current_entity_type_id: currentEntityType.hashid })
          }}
        />
      </div>
    </div>
  )
}

export default AbstractControldocFormTemplateForm;
