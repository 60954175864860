import React, { useState, useEffect, useCallback } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap';

import I18n from 'i18n-js';
import axios from "axios";


export const previewService = (endPoint, formData, callback) => {
  var promise = axios({
    method: 'patch',
    url: endPoint,
    data: formData,
    headers: {
      "X-CSRF-TOKEN": window.compliance.token
    },
    responseType: 'blob'
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
}

const ControldocFormDocumentPreviewPdf = props => {
  const {
    templateDocument,
    currentCustomer,
    form: {
      id: formId
    }
  } = props;

  const [previewPdf, setPreviewPdf] = useState(false)
  const [fileBlob, setFileBlob] = useState(null)

  const defaultRequestParams = {
    tenant: currentCustomer.tenant,
    id: templateDocument.hashid
  }

  const previewPath = Routes.preview_pdf_portal_controldoc_form_document_path;

  const endPoint = previewPath({ ... defaultRequestParams, _options: true })

  const form = document.getElementById(formId)

  const containerDocument = document.getElementById('container-document')
  const containerPreview = document.getElementById('container-preview')

  const onClickPreview = (event) => {
    event.preventDefault();
    setPreviewPdf(!previewPdf)
  }

  const requestPreviewPdf = useCallback(() => {
    const formData = new FormData(form);

    previewService(endPoint, formData, response => {
      if(response.status == 200){
        let blob = new Blob([response.data], { type: 'application/pdf' });
        setFileBlob(blob);
      } else {
        console.error("Invalid JSON: " + response);
      }
    })
  })

  useEffect(() => {
    if(previewPdf){
      requestPreviewPdf()
    }
  }, [previewPdf])


  const modalPreviewPdf = () => {
    return(
      <Modal
        isOpen={ previewPdf }
        toggle={ event => setPreviewPdf(false) }
        onClosed={ event => setFileBlob(null) }
        size='xl'
      >
        <ModalHeader
          toggle={ event => setPreviewPdf(false) }
        >
          Previsualización
        </ModalHeader>
        <ModalBody>
          { function(){
            if(fileBlob){
              return (
                <iframe width="100%" height="750" src={ URL.createObjectURL(fileBlob) } frameBorder="0"/>
              )
            } else {
              return (
                <div className="text-center">
                  <Spinner
                    color="primary"
                  />
                  <p>Cargado previsualización</p>
                </div>
              )
            }
          }()}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={ event => setPreviewPdf(false) }>
            { I18n.t('actions.close') }
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  return(
    <>
      { modalPreviewPdf() }
      <Button
        color='secondary'
        className='mr-3'
        onClick={ event => onClickPreview(event) }
      >
        { I18n.t('actions.preview') }
      </Button>
    </>
  )
}

export default ControldocFormDocumentPreviewPdf;
